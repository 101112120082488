body {
  font-family: Sora, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #fafafa;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 5.5rem;
  margin-bottom: 4rem;
  gap: 4rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (width >= 768px) {
  .container {
    margin-bottom: 7rem;
    margin-top: 8rem;
    gap: 8rem;
  }
}

/* https://stackoverflow.com/a/68568612 */
@supports not (aspect-ratio: 1/1) {
  .container > * + * {
    margin-top: 4rem;
  }

  .inline-flex.gap-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }

  .flex:not([class*='flex-col']).gap-0 > *:not(:last-child) {
    margin-right: 0 !important;
  }

  .inline-flex.gap-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }

  .flex:not([class*='flex-col']).gap-1 > *:not(:last-child) {
    margin-right: 0.25rem !important;
  }

  .inline-flex.gap-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .flex:not([class*='flex-col']).gap-2 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .inline-flex.gap-3 > *:not(:last-child) {
    margin-right: 0.75rem !important;
  }

  .flex:not([class*='flex-col']).gap-3 > *:not(:last-child) {
    margin-right: 0.75rem !important;
  }

  .inline-flex.gap-4 > *:not(:last-child) {
    margin-right: 1rem !important;
  }

  .flex:not([class*='flex-col']).gap-4 > *:not(:last-child) {
    margin-right: 1rem !important;
  }

  .inline-flex.gap-5 > *:not(:last-child) {
    margin-right: 1.25rem !important;
  }

  .flex:not([class*='flex-col']).gap-5 > *:not(:last-child) {
    margin-right: 1.25rem !important;
  }

  .inline-flex.gap-6 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .flex:not([class*='flex-col']).gap-6 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .inline-flex.gap-7 > *:not(:last-child) {
    margin-right: 1.75rem !important;
  }

  .flex:not([class*='flex-col']).gap-7 > *:not(:last-child) {
    margin-right: 1.75rem !important;
  }

  .inline-flex.gap-8 > *:not(:last-child) {
    margin-right: 2rem !important;
  }

  .flex:not([class*='flex-col']).gap-8 > *:not(:last-child) {
    margin-right: 2rem !important;
  }

  .inline-flex.gap-9 > *:not(:last-child) {
    margin-right: 2.25rem !important;
  }

  .flex:not([class*='flex-col']).gap-9 > *:not(:last-child) {
    margin-right: 2.25rem !important;
  }

  .inline-flex.gap-10 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .flex:not([class*='flex-col']).gap-10 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .inline-flex.gap-11 > *:not(:last-child) {
    margin-right: 2.75rem !important;
  }

  .flex:not([class*='flex-col']).gap-11 > *:not(:last-child) {
    margin-right: 2.75rem !important;
  }

  .inline-flex.gap-12 > *:not(:last-child) {
    margin-right: 3rem !important;
  }

  .flex:not([class*='flex-col']).gap-12 > *:not(:last-child) {
    margin-right: 3rem !important;
  }

  .inline-flex.gap-14 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .flex:not([class*='flex-col']).gap-14 > *:not(:last-child) {
    margin-right: 0.5rem !important;
  }

  .inline-flex.gap-16 > *:not(:last-child) {
    margin-right: 4rem !important;
  }

  .flex:not([class*='flex-col']).gap-16 > *:not(:last-child) {
    margin-right: 4rem !important;
  }

  .inline-flex.gap-20 > *:not(:last-child) {
    margin-right: 5rem !important;
  }

  .flex:not([class*='flex-col']).gap-20 > *:not(:last-child) {
    margin-right: 5rem !important;
  }

  .inline-flex.gap-24 > *:not(:last-child) {
    margin-right: 6rem !important;
  }

  .flex:not([class*='flex-col']).gap-24 > *:not(:last-child) {
    margin-right: 6rem !important;
  }

  .inline-flex.gap-28 > *:not(:last-child) {
    margin-right: 7rem !important;
  }

  .flex:not([class*='flex-col']).gap-28 > *:not(:last-child) {
    margin-right: 7rem !important;
  }

  .inline-flex.gap-32 > *:not(:last-child) {
    margin-right: 8rem !important;
  }

  .flex:not([class*='flex-col']).gap-32 > *:not(:last-child) {
    margin-right: 8rem !important;
  }

  .inline-flex.gap-36 > *:not(:last-child) {
    margin-right: 9rem !important;
  }

  .flex:not([class*='flex-col']).gap-36 > *:not(:last-child) {
    margin-right: 9rem !important;
  }

  .inline-flex.gap-40 > *:not(:last-child) {
    margin-right: 10rem !important;
  }

  .flex:not([class*='flex-col']).gap-40 > *:not(:last-child) {
    margin-right: 10rem !important;
  }

  .inline-flex.gap-44 > *:not(:last-child) {
    margin-right: 11rem !important;
  }

  .flex:not([class*='flex-col']).gap-44 > *:not(:last-child) {
    margin-right: 11rem !important;
  }

  .inline-flex.gap-48 > *:not(:last-child) {
    margin-right: 12rem !important;
  }

  .flex:not([class*='flex-col']).gap-48 > *:not(:last-child) {
    margin-right: 12rem !important;
  }

  .inline-flex.gap-52 > *:not(:last-child) {
    margin-right: 13rem !important;
  }

  .flex:not([class*='flex-col']).gap-52 > *:not(:last-child) {
    margin-right: 13rem !important;
  }

  .inline-flex.gap-56 > *:not(:last-child) {
    margin-right: 14rem !important;
  }

  .flex:not([class*='flex-col']).gap-56 > *:not(:last-child) {
    margin-right: 14rem !important;
  }

  .inline-flex.gap-60 > *:not(:last-child) {
    margin-right: 15rem !important;
  }

  .flex:not([class*='flex-col']).gap-60 > *:not(:last-child) {
    margin-right: 15rem !important;
  }

  .inline-flex.gap-64 > *:not(:last-child) {
    margin-right: 16rem !important;
  }

  .flex:not([class*='flex-col']).gap-64 > *:not(:last-child) {
    margin-right: 16rem !important;
  }

  .inline-flex.gap-72 > *:not(:last-child) {
    margin-right: 18rem !important;
  }

  .flex:not([class*='flex-col']).gap-72 > *:not(:last-child) {
    margin-right: 18rem !important;
  }

  .inline-flex.gap-80 > *:not(:last-child) {
    margin-right: 20rem !important;
  }

  .flex:not([class*='flex-col']).gap-80 > *:not(:last-child) {
    margin-right: 20rem !important;
  }

  .inline-flex.gap-96 > *:not(:last-child) {
    margin-right: 24rem !important;
  }

  .flex:not([class*='flex-col']).gap-96 > *:not(:last-child) {
    margin-right: 24rem !important;
  }

  @media only screen and (width <= 767px) {
    .container .flex-col {
      display: grid !important;
      grid-auto-rows: max-content;
    }

    .container .flex-col-reverse {
      display: grid !important;
      grid-auto-rows: max-content;
    }
  }
}
